import React from "react"
import { VscLoading } from "react-icons/vsc"

const BlogStat = (props) => {
  let className = "flex flex-row flex-wrap text-gray-400 space-x-4"
  if (props.className) {
    className += ' ' + props.className
  }
  return (
    <span className={className}>
      {props.date && <p>{props.date}</p>}
      {props.slug && <span id={props.slug} class="leancloud_visitors inline">
        <p className="inline">阅读量：</p>
        <p class="leancloud-visitors-count inline">
          <VscLoading className="inline animate-spin h-auto my-auto"/>
        </p>
      </span>}
    </span>
  )
}

export default BlogStat