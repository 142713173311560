import React from "react"
import { PostLayout } from "../components/layout"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Seo from "../components/seo"
import Comment from "../components/comments"
import BlogStat from "../components/blogStat"
import { components } from "../components/atom"

const PostsPage = ({ data: { mdx } }) => {
  return (
    <PostLayout toc={mdx.frontmatter.toc !== false ? mdx.tableOfContents : null}>
      <Seo title={mdx.frontmatter.title} description={mdx.frontmatter.excerpt} />
      <article className="space-y-5 main xl:max-w-screen-lg">
        <div className="card">
          <p className="text-title">{mdx.frontmatter.title}</p>
          <BlogStat className="mb-8  justify-center" date={mdx.frontmatter.date} slug={mdx.fields.slug} />
          <article className="prose prose-lg prose-slate mx-auto">
            <MDXProvider components={components}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </article>
        </div>
        {mdx.frontmatter.comment !== false &&
          <div className="card"><Comment slug={mdx.fields.slug} /></div>
        }
      </article>
    </PostLayout>
  )
}

export default PostsPage

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY 年 M 月 DD 日")
        comment
        toc
      }
      tableOfContents
    }
  }
`