import React, { PureComponent } from 'react'
import '../style/waline.css'

export default class Comment extends PureComponent {

  async componentDidMount() {
    if (typeof window === "undefined") {
      return
    }
    const Waline = await (await import('@waline/client/')).default
    this._waline = new Waline({
      el: "#waline",
      serverURL: 'https://comment.kottory.me/',
      path: this.props.slug,
      visitor: true,
      emoji: [
        'https://cdn.jsdelivr.net/gh/kottory/waline-emoji@1.0.0/%E5%A4%A7%E8%88%AA%E6%B5%B7%E5%98%89%E7%84%B6/',
        'https://cdn.jsdelivr.net/gh/kottory/waline-emoji@1.0.0/嘉然今天吃什么/'
      ],
      uploadImage: false,
    })

  }

  render() {
    return <div id="waline" style={{ "--waline-theme-color": "#0ea5e9", "--waline-active-color": "#7dd3fc" }} />
  }
}